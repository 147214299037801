import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../components/layout.js'
import SEO from '../components/seo.js'
import Featured from "../components/featured";
import EmailForm from "../components/form";
import phone from "../images/phone6.png";

const SubscriptionPage = props => (
  <Layout>
    <div className="page">
        <SEO
          title={`${props.data.prismicSubscription.data.meta_title.text}`}
          keywords={[`${props.data.prismicSubscription.data.keywords.text}`]}
          description={props.data.prismicSubscription.data.meta_description.text}
          og_image={props.data.prismicSubscription.data.og_image.url}
        />


      <section id="hero" className="container mx-auto flex lg:px-12 hero-section text-gray-700 body-font bg-gray-900 pb-8">
            <div className="lg:px-32 md:px-20 lg:pb-32 container mx-auto flex px-6 lg:pt-32 py-0 lg:flex-row flex-col items-center pt-0" >
                <div
                    className="my-32 lg:my-0 md:w-1/2 flex flex-col items-start text-left mb-16 md:mb-0 ">
                    <h1 className=" lg:text-5xl text-3xl mb-4 font-bold text-gray-200 leading-normal">{props.data.prismicSubscription.data.title.text}</h1>
                    <div
                        className="mt-4 text-gray-300 tracking-wide leading-normal text-lg article-paragraph"
                        dangerouslySetInnerHTML={{ __html: props.data.prismicSubscription.data.paragraph.html}}
                      />
                    {/*<p className="mt-8 mb-8 text-sm text-gray-500 tracking-wide">Join our bi-weekly newsletter to get latest news and tips on building products focused on Baltic region</p>*/}
                    <div className="flex w-full flex-col justify-start lg:flex-row">
                        <EmailForm/>

                    </div>

                </div>
                <div className="hero-image md:w-1/2 w-full ">
                    <img className="" src={phone} />
                </div>
            </div>
        </section>

    </div>
  </Layout>
)

export default SubscriptionPage

export const SubscriptionQuery = graphql`
query Subs {
  prismicSubscription {
    data {
      meta_description {
        text
      }
      meta_title {
        text
      }
      og_image {
        alt
        url
      }
      paragraph {
        html
      }
      title {
        text
      }
      keywords {
        text
      }
    }
  }
}



`